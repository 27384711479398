import React from 'react';
import { graphql } from 'gatsby';
import HeroSpace from '../components/HeroSpace/HeroSpace';
import styles from './404.module.scss';

const NotFoundPage = ({ data }) => (
  <div>
    <HeroSpace
      title="Page Not Found"
      subtitle="Oops, looks like something went wrong... sorry!"
      image={{
        fluid: data.heroImage.fluid,
        alt: 'Icon showing smiley face with Xs for eyes.',
        className: styles.heroGraphic,
      }}
    />
  </div>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query fourOhFour {
    heroImage: imageSharp(fluid: { originalName: { regex: "/dead[.]png/" } }) {
      fluid(maxWidth: 1330, quality: 85, traceSVG: { color: "#ffffff" }) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
`;
